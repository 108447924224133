import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import TopHeader from "../Component/Common/Header";
import Main from "./Main";
import BasicFreight from "./ServiceMenu/BasicFreight";
import AllLocaFreight from "./ServiceMenu/AllLocaFreight";
import AllVcInfo from "./ServiceMenu/AllVcInfo";
import VcInfo1 from "./VcInfoMenu/VcInfo1";
import VcInfo2 from "./VcInfoMenu/VcInfo2";
import VcInfo5 from "./VcInfoMenu/VcInfo5";
import Introduce from "./Company/Introduce";
import ServiceInfo from "./Company/ServiceInfo";
import Footer from "../Component/Common/Footer";
import Trems from "./FooterContents/Trems";
import Privacy from "./FooterContents/Privacy";
import LocationTerm from "./FooterContents/LocationTerm";
import { inject, observer } from "mobx-react";
import { API_INSERT_FREIGHT } from "../common/ApiAddress/FrightAPI";
import Corporation from "./Corporation/Corporation";
import {
  API_SELECT_DONG,
  API_SELECT_SIDO,
  API_SELECT_SIGU,
} from "../common/ApiAddress/CommonAPI";

import CommonAlertWithCallback from "../Component/Common/CommonAlertWithCallback";
import CommonAlert from "../Component/Common/CommonAlert";
import FreightInsertModal from "../Component/Modal/FreightInsertModal";
import MediaQuery from "react-responsive";
import { ReactComponent as FixedBtn } from "../assets/fixedBtn.svg";
@inject((stores) => ({
  commonStore: stores.CommonStore,
}))
@observer
class MainContainer extends React.Component {
  state = {
    freightModalOpen: false,
    textAreaEditable: false,
  };

  fnRouterChage = (path) => {
    const { commonStore } = this.props;
    let prePath = commonStore.path;
    if (prePath == path) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    commonStore.setPath(path);
    this.props.history.push(path);
  };

  fnFreightModalOpen = () => {
    const { commonStore } = this.props;
    commonStore.commonAlertOpen(
      "시스템 점검중 입니다. 대표번호로 연락부탁드립니다."
    );
    // axios({
    //   url: API_SELECT_SIDO,
    //   method: "post",
    // })
    //   .then(function (response) {
    //     if (response.data.resultCode == 100) {
    //       console.log(response.data.data);
    //       commonStore.setSidoList(response.data.data);
    //       commonStore.setUnloadingSidoList(response.data.data);
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // this.setState({
    //   freightModalOpen: true,
    // });
  };

  fnFreightModalClose = () => {
    const { commonStore } = this.props;
    commonStore.initLocation();
    this.setState({
      freightModalOpen: false,
    });
  };

  fnSelectSido = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setSelectedSido(value);
    commonStore.initSelectedSigu(value);
    commonStore.initSelectedDong(value);
    params.sido = value;
    axios({
      url: API_SELECT_SIGU,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setSiguList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSelectSigu = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setSelectedSigu(value);
    commonStore.initSelectedDong(value);
    params.sido = commonStore.selectedSido;
    params.sigu = value;
    axios({
      url: API_SELECT_DONG,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setDongList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUnloadingSelectSido = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setUnloadingSelectedSido(value);
    commonStore.initUnloadingSelectedSigu(value);
    commonStore.intiUnloadingSelectedDong(value);
    params.sido = value;
    axios({
      url: API_SELECT_SIGU,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setUnloadingSiguList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnUnloadingSelectSigu = (value) => {
    let params = {};
    const { commonStore } = this.props;
    let that = this;
    commonStore.setUnloadingSelectedSigu(value);
    commonStore.intiUnloadingSelectedDong(value);
    params.sido = commonStore.unloadingSelectedSido;
    params.sigu = value;
    axios({
      url: API_SELECT_DONG,
      method: "post",
      data: params,
    })
      .then(function (response) {
        if (response.data.resultCode == 100) {
          commonStore.setUnloadingDongList(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnInsertFreight = () => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    params.freightCd = "배차대기";
    params.loadingSido = commonStore.selectedSido;
    params.loadingSigu = commonStore.selectedSigu;
    params.loadingDong = commonStore.selectedDong;
    params.unloadingSido = commonStore.unloadingSelectedSido;
    params.unloadingSigu = commonStore.unloadingSelectedSigu;
    params.unloadingDong = commonStore.unloadingSelectedDong;
    params.vcTyp = commonStore.vcTyp;
    params.vcWeight = commonStore.vcWeight;
    params.freightInfo = commonStore.freightInfo;
    params.loadingDetailAdd = commonStore.loadingDetailAdd;
    params.unloadingDetailAdd = commonStore.unloadingDetailAdd;
    params.hpNo = commonStore.hpNo;

    if (commonStore.selectedSido == null || commonStore.selectedSido == "") {
      commonStore.commonAlertOpen("출발지 시도를 선택해주세요.");
    }
    if (commonStore.selectedSigu == null || commonStore.selectedSigu == "") {
      commonStore.commonAlertOpen("출발지 시군구를 선택해주세요.");
    }
    if (commonStore.selectedDong == null || commonStore.selectedDong == "") {
      commonStore.commonAlertOpen("출발지 읍면동을 선택해주세요.");
    }
    if (
      commonStore.loadingDetailAdd == null ||
      commonStore.loadingDetailAdd == ""
    ) {
      commonStore.commonAlertOpen("출발지 상세주소를 기입해주세요.");
    }

    if (
      commonStore.unloadingSelectedSido == null ||
      commonStore.unloadingSelectedSido == ""
    ) {
      commonStore.commonAlertOpen("도착지 시도를 선택해주세요.");
    }
    if (
      commonStore.unloadingSelectedSigu == null ||
      commonStore.unloadingSelectedSigu == ""
    ) {
      commonStore.commonAlertOpen("도착지 시군구를 선택해주세요.");
    }
    if (
      commonStore.unloadingSelectedDong == null ||
      commonStore.unloadingSelectedDong == ""
    ) {
      commonStore.commonAlertOpen("도착지 읍면동을 선택해주세요.");
    }
    if (
      commonStore.unloadingDetailAdd == null ||
      commonStore.unloadingDetailAdd == ""
    ) {
      commonStore.commonAlertOpen("도착지 상세주소를 기입해주세요.");
    }

    if (commonStore.vcTyp == null || commonStore.vcTyp == "") {
      commonStore.commonAlertOpen("차량종류를 선택해주세요");
    }

    axios({
      url: API_INSERT_FREIGHT,
      method: "post",
      data: params,
    })
      .then(function (response) {
        that.fnGetMsgAuth(params);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnReloadPage = () => {
    const { commonStore } = this.props;
    commonStore.commonAlertWithCallbackClose();
    this.fnFreightModalClose();
    commonStore.initLocation();
  };

  fnOnchangetextAreaEditable = () => {
    this.setState({
      textAreaEditable: !this.state.textAreaEditable,
    });
  };

  fnGetMsgAuth = (obj) => {
    let that = this;
    let params = obj;
    axios({
      url: "https://htruck.co.kr/exapi/msgAuth",
      method: "post",
      data: params,
    })
      .then((response) => {
        that.fnSendMsgFreightRequest(params, response.data);
        that.fnSendMsgFreightAlarm(params, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgFreightRequest = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[배차접수 안내] \n ●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      "\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "화물정보 : " + msgData.freightInfo + "\n";
    content = content + "빠른 시간내 차량배정 후 연락드리겠습니다." + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");
    //phone.push(msgData.hpNo);
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    console.log(content);

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
          commonStore.commonAlertWithCallbackOpen(
            "배차가 성공적으로 접수되었습니다."
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  fnSendMsgFreightAlarm = (msgData, authData) => {
    const { commonStore } = this.props;
    let params = {};
    let that = this;
    let phone = [];
    let id = [];
    let content =
      "[신규 배차접수 안내] \n ●상차지\n" +
      msgData.loadingSido +
      msgData.loadingSigu +
      msgData.loadingDong +
      +"\n";

    if (msgData.loadingDetailAdd != null && msgData.loadingDetailAdd != "") {
      content = content + msgData.loadingDetailAdd + "\n";
    }

    content =
      content +
      "●하차지\n" +
      msgData.unloadingSido +
      msgData.unloadingSigu +
      msgData.unloadingDong +
      "\n";

    if (
      msgData.unloadingDetailAdd != null &&
      msgData.unloadingDetailAdd != ""
    ) {
      content = content + msgData.unloadingDetailAdd + "\n";
    }

    content = content + "화물정보 : " + msgData.freightInfo + "\n";
    content = content + "시스템을 확인해주세요." + "\n";

    //phone.push("01031254802");
    phone.push("01088521982");
    id.push("ttSystem");
    params.access_token = authData.access_token;
    params.text = content;
    params.phone = phone;
    params.id = id;

    console.log(content);

    axios({
      url: "https://htruck.co.kr/exapi/msgSendLmsforTrucker",
      method: "get",
      params: params,
    })
      .then(function (response) {
        if (response.data.code == "1000") {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  render() {
    let currentPage;
    switch (this.props.location.pathname) {
      case "/":
        currentPage = <Main fnFreightModalOpen={this.fnFreightModalOpen} />;
        break;
      case "/BasicFreight":
        currentPage = <BasicFreight fnRouterChage={this.fnRouterChage} />;
        break;
      case "/AllLocaFreight":
        currentPage = <AllLocaFreight fnRouterChage={this.fnRouterChage} />;
        break;
      case "/AllVcInfo":
        currentPage = <AllVcInfo fnRouterChage={this.fnRouterChage} />;
        break;
      case "/VcInfo1":
        currentPage = <VcInfo1 fnRouterChage={this.fnRouterChage} />;
        break;
      case "/VcInfo2":
        currentPage = <VcInfo2 fnRouterChage={this.fnRouterChage} />;
        break;
      case "/VcInfo5":
        currentPage = <VcInfo5 fnRouterChage={this.fnRouterChage} />;
        break;
      case "/corporation":
        currentPage = <Corporation />;
        break;
      case "/introduce":
        currentPage = <Introduce fnRouterChage={this.fnRouterChage} />;
        break;
      case "/serviceInfo":
        currentPage = <ServiceInfo fnRouterChage={this.fnRouterChage} />;
        break;
      case "/terms":
        currentPage = <Trems fnRouterChage={this.fnRouterChage} />;
        break;
      case "/privacy":
        currentPage = <Privacy fnRouterChage={this.fnRouterChage} />;
        break;
      case "/locationTerm":
        currentPage = <LocationTerm fnRouterChage={this.fnRouterChage} />;
        break;
    }

    return (
      <Fragment>
        {/* 모바일버전 */}
        <MediaQuery maxWidth={480}>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{ width: "100%", height: "100px" }}
              className="page-wrap"
            >
              <TopHeader
                fnRouterChage={this.fnRouterChage}
                fnFreightModalOpen={this.fnFreightModalOpen}
              />
            </div>
            <div style={{ width: "100%", height: "auto" }}>{currentPage}</div>
            <div style={{ width: "100%" }}>
              <Footer fnRouterChage={this.fnRouterChage} />
            </div>
            <FreightInsertModal
              fnFreightModalClose={this.fnFreightModalClose}
              fnFreightModalOpen={this.fnFreightModalOpen}
              freightModalOpen={this.state.freightModalOpen}
              fnSelectSido={this.fnSelectSido}
              fnSelectSigu={this.fnSelectSigu}
              fnUnloadingSelectSido={this.fnUnloadingSelectSido}
              fnUnloadingSelectSigu={this.fnUnloadingSelectSigu}
              textAreaEditable={this.state.textAreaEditable}
              fnInsertFreight={this.fnInsertFreight}
              fnOnchangetextAreaEditable={this.fnOnchangetextAreaEditable}
            />
            <CommonAlert />
            <CommonAlertWithCallback callback={this.fnReloadPage} />
            <div
              style={{
                position: "fixed",
                zIndex: "99",
                bottom: "10%",
                right: "5%",
              }}
              onClick={() => {
                this.fnFreightModalOpen();
              }}
            >
              <FixedBtn />
            </div>
          </div>
        </MediaQuery>
        {/* 테블릿버전 */}
        <MediaQuery minWidth={481} maxWidth={1249}>
          <div style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: "100px" }}>
              <TopHeader
                fnRouterChage={this.fnRouterChage}
                fnFreightModalOpen={this.fnFreightModalOpen}
              />
            </div>
            <div style={{ width: "100%", height: "auto" }}>{currentPage}</div>
            <div style={{ width: "100%" }}>
              <Footer fnRouterChage={this.fnRouterChage} />
            </div>
            <FreightInsertModal
              fnFreightModalClose={this.fnFreightModalClose}
              fnFreightModalOpen={this.fnFreightModalOpen}
              freightModalOpen={this.state.freightModalOpen}
              fnSelectSido={this.fnSelectSido}
              fnSelectSigu={this.fnSelectSigu}
              fnUnloadingSelectSido={this.fnUnloadingSelectSido}
              fnUnloadingSelectSigu={this.fnUnloadingSelectSigu}
              textAreaEditable={this.state.textAreaEditable}
              fnInsertFreight={this.fnInsertFreight}
              fnOnchangetextAreaEditable={this.fnOnchangetextAreaEditable}
            />
            <CommonAlert />
            <CommonAlertWithCallback callback={this.fnReloadPage} />
            <div
              style={{
                position: "fixed",
                zIndex: "99",
                bottom: "10%",
                right: "5%",
              }}
              onClick={() => {
                this.fnFreightModalOpen();
              }}
            >
              <FixedBtn />
            </div>
          </div>
        </MediaQuery>
        {/* PC버전 */}
        <MediaQuery minWidth={1250}>
          <div style={{ width: "100%", height: "100%", maxWidth: "1920px" }}>
            <div style={{ width: "100%", height: "100px" }}>
              <TopHeader
                fnRouterChage={this.fnRouterChage}
                fnFreightModalOpen={this.fnFreightModalOpen}
              />
            </div>
            <div style={{ width: "100%", height: "auto" }}>{currentPage}</div>
            <div style={{ width: "100%" }}>
              <Footer fnRouterChage={this.fnRouterChage} />
            </div>
            <FreightInsertModal
              fnFreightModalClose={this.fnFreightModalClose}
              fnFreightModalOpen={this.fnFreightModalOpen}
              freightModalOpen={this.state.freightModalOpen}
              fnSelectSido={this.fnSelectSido}
              fnSelectSigu={this.fnSelectSigu}
              fnUnloadingSelectSido={this.fnUnloadingSelectSido}
              fnUnloadingSelectSigu={this.fnUnloadingSelectSigu}
              textAreaEditable={this.state.textAreaEditable}
              fnInsertFreight={this.fnInsertFreight}
              fnOnchangetextAreaEditable={this.fnOnchangetextAreaEditable}
            />
            <CommonAlert />
            <CommonAlertWithCallback callback={this.fnReloadPage} />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

export default withRouter(MainContainer);
